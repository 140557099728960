interface IDurationTagProps {
    duration: string
}

export default function DurationTag(props: IDurationTagProps) {

    return (
        <div
            style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '100%' 
            }}
        >
            <div 
                style={{ 
                    backgroundColor: getDurationColor(props.duration), 
                    textAlign: 'center',
                    padding: '5px',
                    borderRadius: '5px',
                    fontSize: '16px',
                    fontFamily: 'Arial, sans-serif',
                    color: props.duration === '00:15' ? '#fff' : '#000', 
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                    margin: '5px 0',
                    display: 'inline-block',
                    width: 'fit-content'
                }}
            >
                {props.duration}
            </div>
        </div>
    );
}   

function getDurationColor(duration: string): string {
    // Define the color mapping for each duration
    const durationColorMap: { [key: string]: string } = {
        "10:00": "#ccffcc", // Light green
        "15:00": "#99ff99", // Medium-light green
        "20:00": "#66ff66", // Medium green
        "25:00": "#33ff33", // Medium-dark green
        "30:00": "#00ff00"  // Dark green
    };

    // Return the corresponding color or a default color if the duration is not found
    return durationColorMap[duration] || "#000000"; // Default to black
}