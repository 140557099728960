import { FILTER_LEVEL_STORAGE_KEY, BIOME_FILTER_STORAGE_KEY, STATION_FILTER_STORAGE_KEY, CAULDRON_LEVEL_STORAGE_KEY, DURATION_LEVEL_STORAGE_KEY, WEIGHT_LEVEL_STORAGE_KEY } from '../App';
import foodData, { Food } from '../db/foodData';
import convertDurationToMinutes from './convertDurationToInt';
import { allBiomes } from './getBiome';
import { allCookingStations } from './getCookingStation';

export const getFilterFoodData = (): Food[] => {

  const filterLevel = localStorage.getItem(FILTER_LEVEL_STORAGE_KEY);

  const savedBiomes = localStorage.getItem(BIOME_FILTER_STORAGE_KEY);
  const selectedBiomes = savedBiomes ? JSON.parse(savedBiomes) : allBiomes;

  const savedCookingStations = localStorage.getItem(STATION_FILTER_STORAGE_KEY);
  const selectedCookingStations = savedCookingStations ? JSON.parse(savedCookingStations) : allCookingStations;

  // get the saved cauldron level and store it as an integer
  const savedCauldronLevel: number = parseInt(localStorage.getItem(CAULDRON_LEVEL_STORAGE_KEY) || '1');

  // get the saved duration level and store it as an integer
  const savedDurationLevel: number = parseInt(localStorage.getItem(DURATION_LEVEL_STORAGE_KEY) || '0');

  // Filter based on the biome and cooking station
  const filteredData = foodData.filter((food) => {
    const isBiomeIncluded = selectedBiomes.includes(food.biomeProgression);
    // If the filter level is set to 0, we will include all stations
    const isStationIncluded = selectedCookingStations.includes(food.craftingStation) || filterLevel === '0';
    // if the filter level is greater than zero, then we will filter based on the cauldron level.  If it is 0 then set the cauldron level to 0, otherwise set it to the saved cauldron level
    const cauldronLevel = filterLevel === '0' ? 6 : savedCauldronLevel;
    // if the filter level is 2, then we will filter based on the duration level.  If it is 0 then set the duration level to 0, otherwise set it to the saved duration level
    const durationLevel = filterLevel === '2' ? savedDurationLevel : 0;
    // if the filter level is 2  then we will also filter based on the weight, hiding any foods that are higher than the saved weight level (default weight is 10)
    const weightLevel = filterLevel === '2' ? parseInt(localStorage.getItem(WEIGHT_LEVEL_STORAGE_KEY) || '10') : 10;

    return isBiomeIncluded && isStationIncluded && (food.craftingStationLevel <= cauldronLevel) && (convertDurationToMinutes(food.duration) >= durationLevel) && (food.weight <= weightLevel);
  });


  // Sort based on the order in allBiomes and then by name
  const sortedData = filteredData.sort((a, b) => {
    const biomeOrderA = allBiomes.indexOf(a.biomeProgression);
    const biomeOrderB = allBiomes.indexOf(b.biomeProgression);

    if (biomeOrderA !== biomeOrderB) {
      return biomeOrderA - biomeOrderB; // Sort by biome order
    } else {
      return a.name.localeCompare(b.name); // Sort by name if biomes are the same
    }
  });

 return sortedData;
};
