import React, { useState, useEffect } from 'react';
import BiomeTag from '../Tags/BiomeTag';
import { allBiomes } from '../../helperFunctions/getBiome';

const FILTER_STORAGE_KEY = 'biomeFilter';

interface BiomeFilterProps {
  onBiomesChange: (selectedBiomes: string[]) => void;
}

const BiomeFilter: React.FC<BiomeFilterProps> = ({ onBiomesChange }) => {
  const [selectedBiomes, setSelectedBiomes] = useState<string[]>(() => {
    const savedFilter = localStorage.getItem(FILTER_STORAGE_KEY);
    return savedFilter ? JSON.parse(savedFilter) : allBiomes;
  });

  useEffect(() => {
    localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(selectedBiomes));
  }, [selectedBiomes]);

  const toggleBiome = (biome: string) => {
    const updatedSelectedBiomes = selectedBiomes.includes(biome)
      ? selectedBiomes.filter(b => b !== biome)
      : [...selectedBiomes, biome];

    setSelectedBiomes(updatedSelectedBiomes);
    onBiomesChange(updatedSelectedBiomes);
  };

  return (
    <div style={biomeTagContainerStyle}>
      {allBiomes.map(biome => (
        <BiomeTag
          key={biome}
          biome={biome}
          selected={selectedBiomes.includes(biome)}
          onClick={() => toggleBiome(biome)}
          link={false}
        />
      ))}
    </div>
  );
};

// Inline styles for biome tag container
const biomeTagContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row', 
  gap: '8px',
  marginBottom: '4px'
};

export default BiomeFilter;
