export const getStationColor = (station: string): string => {
  switch (station) {
    case 'Gather':
      return 'rgba(139, 69, 19, 0.6)'; // Pastel SaddleBrown
    case 'Beehive':
      return 'rgba(255, 215, 0, 0.6)'; // Pastel Gold
    case 'Cooking Station':
      return 'rgba(255, 99, 71, 0.6)'; // Pastel Tomato
    case 'Cauldron':
      return 'rgba(106, 90, 205, 0.6)'; // Pastel SlateBlue
    case 'Shaman':
      return 'rgba(46, 139, 87, 0.6)'; // Pastel SeaGreen
    case 'Farm':
      return 'rgba(34, 139, 34, 0.6)'; // Pastel ForestGreen
    case 'Dungeon':
      return 'rgba(139, 0, 0, 0.6)'; // Pastel DarkRed
    case 'Iron Cooking Station':
      return 'rgba(178, 34, 34, 0.6)'; // Pastel FireBrick
    case 'Stone Oven':
      return 'rgba(169, 169, 169, 0.6)'; // Pastel DarkGray
    default:
      return 'rgba(0, 0, 0, 0.6)'; // Pastel Black (transparent black)
  }
};

export const allCookingStations = [
  'Beehive', 
  'Cauldron', 
  'Cooking Station', 
  'Dungeon', 
  'Farm', 
  'Gather', 
  'Iron Cooking Station', 
  'Shaman', 
  'Stone Oven'
];
