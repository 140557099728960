// Function to get the color based on the fork type
export const getForkTypeColor = (forkType: string): string => {
    switch (forkType.toLowerCase()) {
      case 'red':
        return 'red';
      case 'yellow':
        return 'yellow';
      case 'white':
        return 'white';
      case 'blue':
        return 'blue';
      default:
        return 'black'; // default color
    }
  };