
const getBiomeColor = (biome: string): string => {
    switch (biome) {
        case 'Meadows':
            return '#91A75B';
        case 'Black Forest':
            return '#355F3B';
        case 'Swamp':
            return '#A37156';
        case 'Mountain':
            return '#D9D9D9';
        case 'Plains':
            return '#C7C731';
        case 'Ashlands':
            return '#FF0000';
        case 'deep north':
            return '#FFFFFF';
        case 'Mistlands':
            return '#535353';
        default:
            return 'black'; // default color
    }
}

export default getBiomeColor;

export const getBiomeText = (biome: string): string => {
switch (biome) {
    case 'Meadows':
        return 'Meadows';
    case 'Black Forest':
        return 'Black Forest';
    case 'Swamp':
        return 'Swamp';
    case 'Mountain':
        return 'Mountain';
    case 'Plains':
        return 'Plains';
    case 'Ashlands':
        return 'Ashlands';
    case 'deep north':
        return 'Deep North';
    case 'Mistlands':
        return 'Mistlands';
    default:
        return 'Unknown'; 
}
}

export const allBiomes = ['Meadows', 'Black Forest', 'Swamp', 'Mountain', 'Plains', 'Mistlands', 'Ashlands']