import { getStationColor } from "../../helperFunctions/getCookingStation";

interface CookingStationTagProps {
    station: string;
    selected: boolean;
    onClick: () => void;
}

export default function CookingStationTag({ station, selected, onClick }: CookingStationTagProps) {
    const backgroundColor = getStationColor(station);

    return (
        <div 
            style={{
                backgroundColor,
                padding: '5px 10px',
                borderRadius: '5px',
                cursor: 'pointer',
                opacity: selected ? 1 : 0.5,
            }}
            onClick={onClick}
        >
            {station}
        </div>
    );
}