import { useEffect, useState } from "react";
import { allBiomes } from "../helperFunctions/getBiome";
import { allCookingStations } from "../helperFunctions/getCookingStation";
import BiomeTag from "../components/Tags/BiomeTag";
import CookingStationTag from "../components/Tags/CookingStationTag";
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import './Settings.css';
import VCPaper from "../components/Paper/VCPaper";
import { BIOME_FILTER_STORAGE_KEY, STATION_FILTER_STORAGE_KEY, FILTER_LEVEL_STORAGE_KEY, CAULDRON_LEVEL_STORAGE_KEY, DURATION_LEVEL_STORAGE_KEY, WEIGHT_LEVEL_STORAGE_KEY } from "../App";

export default function Settings() {
    const [selectedBiomes, setSelectedBiomes] = useState<string[]>(() => {
        const savedFilter = localStorage.getItem(BIOME_FILTER_STORAGE_KEY);
        return savedFilter ? JSON.parse(savedFilter) : allBiomes;
    });

    const [selectedStations, setSelectedStations] = useState<string[]>(() => {
        const savedFilter = localStorage.getItem(STATION_FILTER_STORAGE_KEY);
        return savedFilter ? JSON.parse(savedFilter) : allCookingStations;
    });

    // 1 for Normal, 0 for Basic, 2 for Advanced
    const [filterLevel, setFilterLevel] = useState<number>(() => {
        const savedFilter = localStorage.getItem(FILTER_LEVEL_STORAGE_KEY);
        return savedFilter ? JSON.parse(savedFilter) : 0;
    });

    const [cauldronLevel, setCauldronLevel] = useState<number>(() => {
        const savedFilter = localStorage.getItem(CAULDRON_LEVEL_STORAGE_KEY);
        return savedFilter ? JSON.parse(savedFilter) : 1;
    });

    const [durationLevel, setDurationLevel] = useState<number>(() => {
        const savedFilter = localStorage.getItem(DURATION_LEVEL_STORAGE_KEY);
        return savedFilter ? JSON.parse(savedFilter) : 0;
    });

    const [weightLevel, setWeightLevel] = useState<number>(() => {
        const savedFilter = localStorage.getItem(WEIGHT_LEVEL_STORAGE_KEY);
        return savedFilter ? JSON.parse(savedFilter) : 10;
    });




    useEffect(() => {
        localStorage.setItem(BIOME_FILTER_STORAGE_KEY, JSON.stringify(selectedBiomes));
    }, [selectedBiomes]);

    useEffect(() => {
        localStorage.setItem(STATION_FILTER_STORAGE_KEY, JSON.stringify(selectedStations));
    }, [selectedStations]);

    useEffect(() => {
        localStorage.setItem(FILTER_LEVEL_STORAGE_KEY, JSON.stringify(filterLevel));
    }, [filterLevel]);

    useEffect(() => {
        localStorage.setItem(CAULDRON_LEVEL_STORAGE_KEY, JSON.stringify(cauldronLevel));
    }, [cauldronLevel]);

    useEffect(() => {
        localStorage.setItem(DURATION_LEVEL_STORAGE_KEY, JSON.stringify(durationLevel));
    }, [durationLevel]);

    useEffect(() => {
        localStorage.setItem(WEIGHT_LEVEL_STORAGE_KEY, JSON.stringify(weightLevel));
    }, [weightLevel]);


    const toggleBiome = (biome: string) => {
        const updatedSelectedBiomes = selectedBiomes.includes(biome)
            ? selectedBiomes.filter(b => b !== biome)
            : [...selectedBiomes, biome];

        setSelectedBiomes(updatedSelectedBiomes);
    };

    const toggleStation = (station: string) => {
        const updatedSelectedStations = selectedStations.includes(station)
            ? selectedStations.filter(s => s !== station)
            : [...selectedStations, station];

        setSelectedStations(updatedSelectedStations);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleFilterLevelSliderChange = (event: Event, newValue: number | number[], activeThumb: number) => {
        if (typeof newValue === 'number') { // Ensure newValue is a number
            setFilterLevel(newValue);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleCauldronLevelSliderChange = (event: Event, newValue: number | number[], activeThumb: number) => {
        if (typeof newValue === 'number') { // Ensure newValue is a number
            setCauldronLevel(newValue);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleDurationLevelSliderChange = (event: Event, newValue: number | number[], activeThumb: number) => {
        if (typeof newValue === 'number') { // Ensure newValue is a number
            setDurationLevel(newValue);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleWeightLevelSliderChange = (event: Event, newValue: number | number[], activeThumb: number) => {
        if (typeof newValue === 'number') { // Ensure newValue is a number
            setWeightLevel(getWeightLabel(newValue));
        }
    };


    return (
        <div className="settings-container">
            <Typography variant="h6" gutterBottom>
                Filter Level
            </Typography>
            <Slider
                value={filterLevel}
                min={0}
                max={2}
                step={1}
                marks={[
                    { value: 0, label: 'Basic' },
                    { value: 1, label: 'Normal' },
                    { value: 2, label: 'Advanced' }
                ]}
                onChange={handleFilterLevelSliderChange}
                aria-labelledby="filter-level-slider"
                sx={{
                    color: 'green', // Slider color
                    '& .MuiSlider-markLabel': {
                        color: 'white', // Label color
                    },
                }}
            />
            <div className="filter-description">
                {filterLevel === 0 && (
                    <p>Basic filter options are limited to just filtering based on biome.  You can change which biomes are filtered directly on the food table page.</p> 
                )}
                {filterLevel === 1 && (
                    <p>Normal filter options include biomes, cooking stations, and cauldron level.  Therefore hiding foods you cannot make due to your cauldron not having the right improvements.</p>
                )}
                {filterLevel === 2 && (
                    <p>Advanced filter allows additional filtering based on the duration of food, and the weight of food.  Light weight foods tend to have shorter durations.</p>
                )}
            </div>
            <VCPaper>
                <h2>Biome Filter</h2>
                <p>Select biomes to display</p>
                <div className="tag-container">
                    {allBiomes.map(biome => (
                        <BiomeTag
                            key={biome}
                            biome={biome}
                            selected={selectedBiomes.includes(biome)}
                            onClick={() => toggleBiome(biome)}
                            link={false}
                        />
                    ))}
                </div>
            </VCPaper>
            {filterLevel > 0 && (
                <>
                    <VCPaper>
                        <h2>Cooking Station Filter</h2>
                        <p>Select cooking stations to display</p>
                        <div className="tag-container" style={{ marginBottom: '16px'}}>
                            {allCookingStations.map(station => (
                                <CookingStationTag
                                    key={station}
                                    station={station}
                                    selected={selectedStations.includes(station)}
                                    onClick={() => toggleStation(station)}
                                />
                            ))}
                        </div>
                        {/* we only want to display the cauldron slider if the cauldron is selected */}
                        {selectedStations.includes('Cauldron') && (
                            <VCPaper>
                                <Typography variant="h6" gutterBottom>
                                    Cauldron Level
                                </Typography>
                                <Slider
                                    value={cauldronLevel}
                                    min={1}
                                    max={6}
                                    step={1}
                                    marks={[
                                        { value: 1, label: '1' },
                                        { value: 2, label: '2' },
                                        { value: 3, label: '3' },
                                        { value: 4, label: '4' },
                                        { value: 5, label: '5' },
                                        { value: 6, label: '6' }
                                    ]}
                                    onChange={handleCauldronLevelSliderChange}
                                    aria-labelledby="filter-level-slider"
                                    sx={{
                                        color: 'rgba(106, 90, 205, 0.6)', // Slider color
                                        '& .MuiSlider-markLabel': {
                                            color: 'white', // Label color
                                        },
                                    }}
                                />
                            </VCPaper>
                        )}
                    </VCPaper>
                </>
            )}
            {filterLevel === 2 && (
                <>
                    <VCPaper>
                        <h2>Duration Filter</h2>
                        <p>Select a duration length, foods that do not last that long will not be displayed.</p>
                        <div className="duration-filter">
                        <Slider
                            value={durationLevel}
                            min={0}
                            max={30}
                            step={1}
                            marks={[
                                { value: 0, label: '0' },
                                { value: 10, label: '10' },
                                { value: 15, label: '15' },
                                { value: 20, label: '20' },
                                { value: 25, label: '25' },
                                { value: 30, label: '30' }
                                ]}
                                onChange={handleDurationLevelSliderChange}
                                aria-labelledby="filter-level-slider"
                                sx={{
                                    color: 'green', // Slider color
                                    '& .MuiSlider-markLabel': {
                                        color: 'white', // Label color
                                    },
                                }}
                                />
                        </div>
                    </VCPaper>
                </>
            )}
            {filterLevel === 2 && (
                <>
                    <VCPaper>
                        <h2>Weight Filter</h2>
                        <p>Select a weight, foods that weigh more than that will not be displayed.</p>
                        <div className="weight-filter">
                        <Slider
                            value={getWeightSliderValue(weightLevel)}
                            min={0}
                            max={20}
                            step={1}
                            marks={[
                                { value: 0, label: '0' },
                                { value: 1, label: '0.1' },
                                { value: 2, label: '0.2' },
                                { value: 3, label: '0.3' },
                                { value: 5, label: '0.5' },
                                { value: 10, label: '1' },
                                { value: 20, label: '10' }
                                ]}
                                onChange={handleWeightLevelSliderChange}
                                aria-labelledby="filter-level-slider"
                                sx={{
                                    color: 'gold', // Slider color
                                    '& .MuiSlider-markLabel': {
                                        color: 'white', // Label color
                                    },
                                }}
                                />
                        </div>
                    </VCPaper>
                </>
            )}
        </div>
    );
}


function getWeightLabel(value: number) {
    if (value === 0) {
        return 0;
    } else if (value === 1) {
        return 0.1;
    } else if (value === 2) {
        return 0.2;
    } else if (value === 3) {
        return 0.3;
    } else if (value === 5) {
        return 0.5;
    } else if (value === 10) {
        return 1;
    } else if (value === 20) {
        return 10;
    } else {
        return 0;
    }
}

function getWeightSliderValue(label: number) {
    if (label === 0) {
        return 0;
    } else if (label === 0.1) {
        return 1;
    } else if (label === 0.2) {
        return 2;
    } else if (label === 0.3) {
        return 3;
    } else if (label === 0.5) {
        return 5;
    } else if (label === 1) {
        return 10;
    } else if (label === 10) {
        return 20;
    } else {
        return 0;
    }
}