import getBiomeColor, { getBiomeText } from "../../helperFunctions/getBiome";

interface IBiomeTagProps {
  biome: string;
  selected?: boolean;
  onClick?: () => void;
  link: boolean
}

export default function BiomeTag(props: IBiomeTagProps) {
  const { biome, selected, onClick } = props;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
      else if (props.link) {
        window.location.href = `/${biome.toLowerCase()}`;
      }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        opacity: !selected && onClick ? 0.5 : 1, // Fade out if not selected, but don't fade if not clickable
        cursor: onClick ? 'pointer' : 'default' // Change pointer on hover if there's an onClick callback
      }}
      onClick={handleClick}
    >
      <div
        style={{
          backgroundColor: getBiomeColor(biome),
          textAlign: 'center',
          padding: '5px',
          borderRadius: '5px',
          fontSize: '16px',
          fontFamily: 'Arial, sans-serif',
          color: '#fff', // Assuming a light color for text
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
          margin: '5px 0',
          display: 'inline-block',
          width: 'fit-content',
          cursor: 'pointer'
        }}
      >
        {getBiomeText(biome)}
      </div>
    </div>
  );
}
