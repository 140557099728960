import { useParams } from 'react-router-dom';
import foodData from '../db/foodData';
import NotFound from '../components/NotFound';
import FoodDetail from './FoodDetails';
import BiomeDetail from './Biome';

export default function DynamicPage() {
  const { '*': dynamicString } = useParams<{ '*': string }>(); // Get the wildcard parameter from the URL
  // if we haven't found anything then return a 404
  if (!dynamicString) {
    return <NotFound />;
  }
  // is the wildcard parameter a food name?
  if (foodData.some(food => food.name.toLowerCase() === dynamicString.toLowerCase())) {
    return <FoodDetail str={dynamicString} />;
  }
  // is the wildcard parameter a biome name?
  if (foodData.some(food => food.biomeProgression.toLowerCase() === dynamicString.toLowerCase())) {
    return <BiomeDetail str={dynamicString} />;
  }
  // if we haven't found anything then return a 404
  return <NotFound />;
}



