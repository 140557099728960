import React from 'react';
import Paper from '@mui/material/Paper';

interface VCPaperProps {
  children: React.ReactNode;
}

export default function VCPaper({ children }: VCPaperProps) {
  const combinedStyle: React.CSSProperties = {
    padding: '16px',
    backgroundColor: '#1e1e1e',
    color: 'whitesmoke',
  };

  return (
    <Paper square={false} style={combinedStyle}>
      {children}
    </Paper>
  );
};

