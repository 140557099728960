import React, { useState } from 'react';
import CookingStationCard from './CookingStationCard';
import { allBiomes } from '../../helperFunctions/getBiome';
import BiomeFilter from '../BiomeFilter/BiomeFilter';
import { allCookingStations } from '../../helperFunctions/getCookingStation';

const CookingStations: React.FC = () => {
  const [selectedBiomes, setSelectedBiomes] = useState<string[]>(() => {
    const savedFilter = localStorage.getItem('biomeFilter');
    return savedFilter ? JSON.parse(savedFilter) : allBiomes;
  });

  const handleBiomesChange = (updatedBiomes: string[]) => {
    setSelectedBiomes(updatedBiomes);
  };


  return (
    <div style={containerStyle}>
      {/* Display biome filter */}
      <div style={filterContainerStyle}>
        <BiomeFilter onBiomesChange={handleBiomesChange} />
      </div>

      <div style={contentContainerStyle}>
        {/* Loop through all the stations and display the CookingStationCard for each */}
        {allCookingStations.map(station => (
          <CookingStationCard 
            key={station} 
            stationName={station} 
            selectedBiomes={selectedBiomes} 
          />
        ))}
      </div>
    </div>
  );
};

// Inline styles for the container
const containerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
};

const filterContainerStyle: React.CSSProperties = {
    display: 'flex',
  marginTop: '0px', 
  marginRight: '0px',
  justifyContent: 'flex-end',
};

const contentContainerStyle: React.CSSProperties = {
  flex: 1,
  flexDirection: 'row',
  padding: '4px',
};

export default CookingStations;
