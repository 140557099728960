import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FoodTable from "./components/Foodtable/FoodTable";
import Home from "./components/Home";
import NavBar from "./components/NavBar/NavBar";
import CookingStations from "./components/FoodItem/CookingStations";
import FoodDetail from "./pages/DynamicPage";
import Settings from "./pages/Settings";
import foodData from "./db/foodData";
import Footer from "./components/Footer/footer";
import TheLongestRoad from "./pages/TheLongestRoad";

// variables that might be used in different places
export const maxHealth = foodData.reduce(
  (max, food) => Math.max(max, food.health),
  0
);
export const maxStamina = foodData.reduce(
  (max, food) => Math.max(max, food.stamina),
  0
);
export const maxHealing = foodData.reduce(
  (max, food) => Math.max(max, parseInt(food.healing)),
  0
);
export const maxEitr = foodData.reduce(
  (max, food) => Math.max(max, food.eitr),
  0
);
export const maxTotal = foodData.reduce(
  (max, food) => Math.max(max, food.total),
  0
);

// local storage keys
export const BIOME_FILTER_STORAGE_KEY = "biomeFilter";
export const STATION_FILTER_STORAGE_KEY = "stationFilter";
export const FILTER_LEVEL_STORAGE_KEY = "filterLevel";
export const CAULDRON_LEVEL_STORAGE_KEY = "cauldronLevel";
export const DURATION_LEVEL_STORAGE_KEY = "durationLevel";
export const WEIGHT_LEVEL_STORAGE_KEY = "weightLevel";

export const TotalFoodsInDatabase = foodData.length;

function App() {
  return (
    <Router>
      {" "}
      {/* Set up the router */}
      <div className="App">
        <NavBar /> {/* Use the imported NavBar component */}
        <Routes>
          <Route path="/" element={<Home />} /> {/* Home page route */}
          <Route path="/food-table" element={<FoodTable />} />{" "}
          {/* Food table route */}
          <Route path="/cooking-stations" element={<CookingStations />} />
          <Route path="/settings" element={<Settings />} />{" "}
          {/* Settings route */}
          <Route path="/thelongestroad" element={<TheLongestRoad />} />{" "}
          {/* The Longest Road route */}
          <Route path="*" element={<FoodDetail />} /> {/* Dynamic route */}
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
