import '../../App.css';

export default function Footer() {
    return (
        <footer className='footer'>
            <p>&copy; {new Date().getFullYear()} Bug Busters. All rights reserved.</p>
            <a href="https://www.buymeacoffee.com/countdisoQ" target="_blank" rel="noopener noreferrer" style={{marginRight: '10px'}}>
                <img 
                    src="https://cdn.buymeacoffee.com/buttons/v2/default-violet.png" 
                    alt="Buy Me A Coffee" 
                    style={{ height: '40px', width: '217px' }}
                />
            </a>
        </footer>
    );
}

