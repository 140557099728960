import TableBar, { FoodTableBarType } from "../components/Foodtable/Bar";
import BiomeTag from "../components/Tags/BiomeTag";
import CraftingStationCell from "../components/Foodtable/CraftingStationCell";
import NotFound from "../components/NotFound";
import foodData from "../db/foodData";
import './FoodDetail.css';
import DurationTag from "../components/Tags/DurationTag";
import { getImageURLRemoveSpaces } from "../helperFunctions/getImageURL";

interface IFoodDetail {
    str: string;
}

export default function FoodDetail(props: IFoodDetail) {
    const foodItem = foodData.find(fd => fd.name.toLowerCase() === props.str?.toLowerCase());
  
    if (!foodItem) {
      return <NotFound />;
    }
  
    return (
        <div className="food-detail-container">
          <h1>{foodItem.name}</h1>
          <div className="food-detail-content">
            <div className="food-icon-placeholder">
              <img src={getImageURLRemoveSpaces(foodItem.icon)} alt={foodItem.name} />
            </div>
            <table className="food-stats">
              <tbody>
                <tr className="food-stat-item">
                  <td className="food-stat-label">Health:</td>
                  <td><TableBar value={foodItem.health} maxValue={100} type={FoodTableBarType.Health} /></td>
                </tr>
                <tr className="food-stat-item">
                  <td className="food-stat-label">Stamina:</td>
                  <td><TableBar value={foodItem.stamina} maxValue={100} type={FoodTableBarType.Stamina} /></td>
                </tr>
                {foodItem.eitr > 0 && (
                  <tr className="food-stat-item">
                    <td className="food-stat-label">Eitr:</td>
                    <td><TableBar value={foodItem.eitr} maxValue={100} type={FoodTableBarType.Eitr} /></td>
                  </tr>
                )}
                <tr className="food-stat-item">
                  <td className="food-stat-label">Total:</td>
                  <td><TableBar value={foodItem.total} maxValue={100} type={FoodTableBarType.Total} /></td>
                </tr>
                <tr className="food-stat-item">
                  <td className="food-stat-label">Healing:</td>
                  <td><TableBar value={parseInt(foodItem.healing)} maxValue={100} type={FoodTableBarType.Healing} /></td>
                </tr>
                <tr className="food-stat-item">
                  <td className="food-stat-label">Duration:</td>
                  <td><DurationTag duration={foodItem.duration} /></td>
                </tr>
                <tr className="food-stat-item">
                  <td className="food-stat-label">Weight:</td>
                  <td>{foodItem.weight}</td>
                </tr>
                <tr className="food-stat-item">
                  <td className="food-stat-label">Stack Size:</td>
                  <td>{foodItem.stackSize}</td>
                </tr>
                <tr className="food-stat-item">
                  <td className="food-stat-label">Biome Progression:</td>
                  <td><BiomeTag biome={foodItem.biomeProgression} link /></td>
                </tr>
                <tr className="food-stat-item">
                  <td className="food-stat-label">Crafting Station:</td>
                  <td>
                    <CraftingStationCell 
                      cell={{ 
                        craftingStation: foodItem.craftingStation, 
                        craftingStationLevel: foodItem.craftingStationLevel 
                      }} 
                    />
                  </td>
                </tr>
                <tr className="food-stat-item">
                  <td className="food-stat-label">Crafting Multiplier:</td>
                  <td>{foodItem.craftingMultiplier}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
  };
