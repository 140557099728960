export default function TheLongestRoad() {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "space-evenly",
			}}>
			<video
				controls
				autoPlay
				className="responsive-video"
				style={{ width: "60%", height: "60%" }}>
				<source
					src={require("./TheLongestRoad.mp4")}
					type="video/mp4"
				/>
				Your browser does not support the video tag..
			</video>
		</div>
	);
}
