import TableBar, { FoodTableBarType } from "../components/Foodtable/Bar";
import CraftingStationCell from "../components/Foodtable/CraftingStationCell";
import NotFound from "../components/NotFound";
import DurationTag from "../components/Tags/DurationTag";
import foodData from "../db/foodData";
import { getImageURLRemoveSpaces } from "../helperFunctions/getImageURL";
import './FoodDetail.css';


interface IBiomeDetail {
    str: string;
}

export default function BiomeDetail(props: IBiomeDetail) {
    const matchingFoodItems = foodData.filter(fd => fd.biomeProgression.toLowerCase() === props.str?.toLowerCase());
  
    if (matchingFoodItems.length === 0) {
        return <NotFound />;
    }

    // lets order the food based on name
    matchingFoodItems.sort((a, b) => a.name.localeCompare(b.name));
  
    return (
        <div className="biome-header">
                <h1>Biome: {props.str}</h1>
            <div className="biome-detail-container">
                {matchingFoodItems.map(foodItem => (
                    <div key={foodItem.name} className="food-detail-container-biome" style={{ marginBottom: '8px'}}>
                        <h2>{foodItem.name}</h2>
                        <div className="food-detail-content">
                            <div className="food-icon-placeholder">
                                <img src={getImageURLRemoveSpaces(foodItem.icon)} alt={foodItem.name} />
                            </div>
                            <table className="food-stats">
                                <tbody>
                                    <tr className="food-stat-item">
                                        <td className="food-stat-label">Health:</td>
                                        <td><TableBar value={foodItem.health} maxValue={100} type={FoodTableBarType.Health} /></td>
                                    </tr>
                                    <tr className="food-stat-item">
                                        <td className="food-stat-label">Stamina:</td>
                                        <td><TableBar value={foodItem.stamina} maxValue={100} type={FoodTableBarType.Stamina} /></td>
                                    </tr>
                                    {foodItem.eitr > 0 && (
                                        <tr className="food-stat-item">
                                            <td className="food-stat-label">Eitr:</td>
                                            <td><TableBar value={foodItem.eitr} maxValue={100} type={FoodTableBarType.Eitr} /></td>
                                        </tr>
                                    )}
                                    <tr className="food-stat-item">
                                        <td className="food-stat-label">Total:</td>
                                        <td><TableBar value={foodItem.total} maxValue={100} type={FoodTableBarType.Total} /></td>
                                    </tr>
                                    <tr className="food-stat-item">
                                        <td className="food-stat-label">Healing:</td>
                                        <td><TableBar value={parseInt(foodItem.healing)} maxValue={100} type={FoodTableBarType.Healing} /></td>
                                    </tr>
                                    <tr className="food-stat-item">
                                        <td className="food-stat-label">Duration:</td>
                                        <td><DurationTag duration={foodItem.duration} /></td>
                                    </tr>
                                    <tr className="food-stat-item">
                                        <td className="food-stat-label">Weight:</td>
                                        <td>{foodItem.weight}</td>
                                    </tr>
                                    <tr className="food-stat-item">
                                        <td className="food-stat-label">Stack Size:</td>
                                        <td>{foodItem.stackSize}</td>
                                    </tr>
                                    <tr className="food-stat-item">
                                        <td className="food-stat-label">Crafting Station:</td>
                                        <td>
                                            <CraftingStationCell 
                                                cell={{ 
                                                    craftingStation: foodItem.craftingStation, 
                                                    craftingStationLevel: foodItem.craftingStationLevel 
                                                }} 
                                            />
                                        </td>
                                    </tr>
                                    <tr className="food-stat-item">
                                        <td className="food-stat-label">Crafting Multiplier:</td>
                                        <td>{foodItem.craftingMultiplier}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
