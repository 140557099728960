import React from 'react';
import foodData from '../../db/foodData';
import getBiomeColor from '../../helperFunctions/getBiome';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import HealthIcon from '@mui/icons-material/Favorite';
import StaminaIcon from '@mui/icons-material/FitnessCenter';
import EitrIcon from '@mui/icons-material/AutoAwesome';
import HealingIcon from '@mui/icons-material/Healing';
import DurationIcon from '@mui/icons-material/HourglassFull';

interface FoodCardProps {
  foodName: string;
}

const FoodCard: React.FC<FoodCardProps> = ({ foodName }) => {
  // find the matching food from foodData matching the name
  const food = foodData.find(food => food.name === foodName);
  if (!food) {
    return <div></div>;
  }

  // Inline styles for the card
  const cardStyle: React.CSSProperties = {
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '16px',
    width: '200px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    margin: '16px',
    backgroundColor: getBiomeColor(food.biomeProgression),
    textAlign: 'center' // Center the title
  };

  const iconCardStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#000',
    color: '#fff',
    padding: '8px',
    borderRadius: '4px',
    margin: '4px',
    width: 'fit-content'
  };

  const iconStyle: React.CSSProperties = {
    marginRight: '8px',
    verticalAlign: 'middle'
  };

  const rowStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    margin: '8px 0'
  };

  const titleStyle: React.CSSProperties = {
    fontFamily: 'Arial, sans-serif', // Change to your preferred font
    fontWeight: 'bold', // Make the font bold
    fontSize: '1.2em', // Adjust the font size
    marginBottom: '16px'
  };

  return (
    <Paper elevation={6} style={cardStyle}>
      <h3 style={titleStyle}>{food.name}</h3>
      <div style={rowStyle}>
        <Tooltip title="Health" arrow>
          <div style={iconCardStyle}>
            <HealthIcon style={{ ...iconStyle, color: 'red' }} />
            {food.health}
          </div>
        </Tooltip>
        <Tooltip title="Stamina" arrow>
          <div style={iconCardStyle}>
            <StaminaIcon style={{ ...iconStyle, color: 'orange' }} />
            {food.stamina}
          </div>
        </Tooltip>
        <Tooltip title="Eitr (Magic)" arrow>
          <div style={iconCardStyle}>
            <EitrIcon style={{ ...iconStyle, color: 'blue' }} />
            {food.eitr}
          </div>
        </Tooltip>
      </div>
      <div style={rowStyle}>
        <Tooltip title="Healing" arrow>
          <div style={iconCardStyle}>
            <HealingIcon style={{ ...iconStyle, color: 'green' }} />
            {food.healing}
          </div>
        </Tooltip>
        <Tooltip title="Duration" arrow>
          <div style={iconCardStyle}>
            <DurationIcon style={{ ...iconStyle, color: 'purple' }} />
            {food.duration}
          </div>
        </Tooltip>
      </div>
    </Paper>
  );
};

export default FoodCard;
