export type CraftingStation = 'Gather' | 'Beehive' | 'Cooking Station' | 'Cauldron' | 'Shaman' | 'Farm' | 'Dungeon' | 'Iron Cooking Station' | 'Stone Oven';

export interface Food {
  name: string;
  icon: string;
  health: number;
  stamina: number;
  eitr: number;
  total: number;
  rawMaterials: string[];
  forkType: string;
  healing: string;
  duration: string;
  weight: number;
  stackSize: number;
  biomeProgression: string;
  craftingStation: CraftingStation;
  craftingStationLevel: number;
  craftingMultiplier: number;
}


export const foodData: Food[] = [
  {
    name: "Raspberries",
    icon: "Raspberry",
    health: 7,
    stamina: 20,
    eitr: 0,
    total: 27,
    rawMaterials: [],
    forkType: "Yellow",
    healing: "1",
    duration: "10:00",
    weight: 0.1,
    stackSize: 50,
    biomeProgression: "Meadows",
    craftingStation: "Gather",
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Mushroom",
    icon: "Mushroom",
    health: 15,
    stamina: 15,
    eitr: 0,
    total: 30,
    rawMaterials: [],
    forkType: "White",
    healing: "1",
    duration: "15:00",
    weight: 0.1,
    stackSize: 50,
    biomeProgression: "Meadows",
    craftingStation: "Gather",
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Honey",
    icon: "Honey",
    health: 8,
    stamina: 35,
    eitr: 0,
    total: 43,
    rawMaterials: [],
    forkType: "Yellow",
    healing: "1",
    duration: "15:00",
    weight: 0.2,
    stackSize: 50,
    biomeProgression: "Meadows",
    craftingStation: "Beehive",
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Grilled neck tail",
    icon: "NeckTailGrilled",
    health: 25,
    stamina: 8,
    eitr: 0,
    total: 33,
    rawMaterials: ["Neck tail x1"],
    forkType: "Red",
    healing: "2",
    duration: "20:00",
    weight: 0.5,
    stackSize: 20,
    biomeProgression: "Meadows",
    craftingStation: 'Cooking Station',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Cooked boar meat",
    icon: "Cooked Boar Meat",
    health: 30,
    stamina: 10,
    eitr: 0,
    total: 40,
    rawMaterials: ["Boar meat x1"],
    forkType: "Red",
    healing: "2",
    duration: "20:00",
    weight: 1.0,
    stackSize: 20,
    biomeProgression: "Meadows",
    craftingStation: 'Cooking Station',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Cooked deer meat",
    icon: "Cooked Deer Meat",
    health: 35,
    stamina: 12,
    eitr: 0,
    total: 47,
    rawMaterials: ["Deer meat x1"],
    forkType: "Red",
    healing: "2",
    duration: "20:00",
    weight: 1.0,
    stackSize: 20,
    biomeProgression: "Meadows",
    craftingStation: 'Cooking Station',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Boar jerky",
    icon: "BoarJerky",
    health: 23,
    stamina: 23,
    eitr: 0,
    total: 46,
    rawMaterials: ["Boar meat x1", "Honey x1"],
    forkType: "White",
    healing: "2",
    duration: "30:00",
    weight: 0.5,
    stackSize: 20,
    biomeProgression: "Black Forest",
    craftingStation: 'Cauldron',
    craftingStationLevel: 1,
    craftingMultiplier: 2
  },
  {
    name: "Bukeperries",
    icon: "Bukeperries",
    health: -100,
    stamina: -100,
    eitr: 0,
    total: 0,
    rawMaterials: [],
    forkType: "N/A",
    healing: "0",
    duration: "00:15",
    weight: 0.1,
    stackSize: 50,
    biomeProgression: "Black Forest",
    craftingStation: 'Shaman',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Blueberries",
    icon: "Blueberries",
    health: 8,
    stamina: 25,
    eitr: 0,
    total: 33,
    rawMaterials: [],
    forkType: "Yellow",
    healing: "1",
    duration: "10:00",
    weight: 0.1,
    stackSize: 50,
    biomeProgression: "Black Forest",
    craftingStation: 'Gather',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Carrot",
    icon: "Carrot",
    health: 10,
    stamina: 32,
    eitr: 0,
    total: 42,
    rawMaterials: [],
    forkType: "Yellow",
    healing: "1",
    duration: "15:00",
    weight: 0.3,
    stackSize: 50,
    biomeProgression: "Black Forest",
    craftingStation: 'Farm',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Yellow mushroom",
    icon: "Yellow mushroom",
    health: 10,
    stamina: 30,
    eitr: 0,
    total: 40,
    rawMaterials: [],
    forkType: "Yellow",
    healing: "1",
    duration: "10:00",
    weight: 0.1,
    stackSize: 50,
    biomeProgression: "Black Forest",
    craftingStation: 'Dungeon',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Carrot soup",
    icon: "Carrot Soup",
    health: 15,
    stamina: 45,
    eitr: 0,
    total: 60,
    rawMaterials: ["Mushroom x1", "Carrot x3"],
    forkType: "Yellow",
    healing: "2",
    duration: "25:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Black Forest",
    craftingStation: 'Cauldron',
    craftingStationLevel: 1,
    craftingMultiplier: 0
  },
  {
    name: "Queen's jam",
    icon: "QueensJam",
    health: 14,
    stamina: 40,
    eitr: 0,
    total: 54,
    rawMaterials: ["Raspberries x8", "Blueberries x6"],
    forkType: "Yellow",
    healing: "2",
    duration: "20:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Black Forest",
    craftingStation: 'Cauldron',
    craftingStationLevel: 1,
    craftingMultiplier: 4
  },
  {
    name: "Cooked fish",
    icon: "Cooked fish",
    health: 45,
    stamina: 15,
    eitr: 0,
    total: 60,
    rawMaterials: ["Raw fish x1"],
    forkType: "Red",
    healing: "2",
    duration: "20:00",
    weight: 0.5,
    stackSize: 20,
    biomeProgression: "Black Forest",
    craftingStation: 'Cooking Station',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Deer stew",
    icon: "Deer Stew",
    health: 45,
    stamina: 15,
    eitr: 0,
    total: 60,
    rawMaterials: ["Blueberries x1", "Carrot x1", "Cooked deer meat x1"],
    forkType: "Red",
    healing: "3",
    duration: "25:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Black Forest",
    craftingStation: 'Cauldron',
    craftingStationLevel: 1,
    craftingMultiplier: 0
  },
  {
    name: "Minced meat sauce",
    icon: "Minced Meat Sauce",
    health: 40,
    stamina: 13,
    eitr: 0,
    total: 53,
    rawMaterials: ["Boar meat x1", "Neck tail x1", "Carrot x1"],
    forkType: "Red",
    healing: "3",
    duration: "25:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Black Forest",
    craftingStation: 'Cauldron',
    craftingStationLevel: 1,
    craftingMultiplier: 0
  },
  {
    name: "Muckshake",
    icon: "ShocklateSmoothie",
    health: 16,
    stamina: 50,
    eitr: 0,
    total: 66,
    rawMaterials: ["Ooze x1", "Raspberries x2", "Blueberries x2"],
    forkType: "Yellow",
    healing: "1",
    duration: "20:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Swamp",
    craftingStation: 'Cauldron',
    craftingStationLevel: 2,
    craftingMultiplier: 0
  },
  {
    name: "Turnip stew",
    icon: "Turnip Stew",
    health: 18,
    stamina: 55,
    eitr: 0,
    total: 73,
    rawMaterials: ["Boar meat x1", "Turnip x3"],
    forkType: "Yellow",
    healing: "2",
    duration: "25:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Swamp",
    craftingStation: 'Cauldron',
    craftingStationLevel: 2,
    craftingMultiplier: 0
  },
  {
    name: "Sausages",
    icon: "Sausages",
    health: 55,
    stamina: 18,
    eitr: 0,
    total: 73,
    rawMaterials: ["Entrails x4", "Boar meat x1", "Thistle x1"],
    forkType: "Red",
    healing: "3",
    duration: "25:00",
    weight: 0.5,
    stackSize: 20,
    biomeProgression: "Swamp",
    craftingStation: 'Cauldron',
    craftingStationLevel: 2,
    craftingMultiplier: 4
  },
  {
    name: "Black soup",
    icon: "Black Soup",
    health: 50,
    stamina: 17,
    eitr: 0,
    total: 67,
    rawMaterials: ["Bloodbag x1", "Honey x1", "Turnip x1"],
    forkType: "Red",
    healing: "3",
    duration: "20:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Swamp",
    craftingStation: 'Cauldron',
    craftingStationLevel: 2,
    craftingMultiplier: 0
  },
  {
    name: "Cooked serpent meat",
    icon: "Cooked serpent meat",
    health: 70,
    stamina: 23,
    eitr: 0,
    total: 93,
    rawMaterials: ["Serpent meat x1"],
    forkType: "Red",
    healing: "3",
    duration: "25:00",
    weight: 10.0,
    stackSize: 50,
    biomeProgression: "Swamp",
    craftingStation: 'Iron Cooking Station',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Serpent stew",
    icon: "Serpent Stew",
    health: 80,
    stamina: 26,
    eitr: 0,
    total: 106,
    rawMaterials: ["Mushroom x1", "Cooked serpent meat x1", "Honey x2"],
    forkType: "Red",
    healing: "4",
    duration: "30:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Swamp",
    craftingStation: 'Cauldron',
    craftingStationLevel: 2,
    craftingMultiplier: 0
  },
  {
    name: "Onion",
    icon: "Onion",
    health: 13,
    stamina: 40,
    eitr: 0,
    total: 53,
    rawMaterials: [],
    forkType: "Yellow",
    healing: "1",
    duration: "15:00",
    weight: 0.3,
    stackSize: 50,
    biomeProgression: "Mountain",
    craftingStation: 'Farm',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Onion soup",
    icon: "OnionSoup",
    health: 20,
    stamina: 60,
    eitr: 0,
    total: 80,
    rawMaterials: ["Onion x3"],
    forkType: "Yellow",
    healing: "1",
    duration: "20:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Mountain",
    craftingStation: 'Cauldron',
    craftingStationLevel: 3,
    craftingMultiplier: 0
  },
  {
    name: "Cooked wolf meat",
    icon: "CookedWolfMeat",
    health: 45,
    stamina: 15,
    eitr: 0,
    total: 60,
    rawMaterials: ["Wolf meat x1"],
    forkType: "Red",
    healing: "3",
    duration: "20:00",
    weight: 1.0,
    stackSize: 20,
    biomeProgression: "Mountain",
    craftingStation: 'Cooking Station',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Wolf jerky",
    icon: "WolfJerky",
    health: 33,
    stamina: 33,
    eitr: 0,
    total: 66,
    rawMaterials: ["Wolf meat x1", "Honey x1"],
    forkType: "White",
    healing: "3",
    duration: "30:00",
    weight: 0.5,
    stackSize: 20,
    biomeProgression: "Mountain",
    craftingStation: 'Cauldron',
    craftingStationLevel: 3,
    craftingMultiplier: 2
  },
  {
    name: "Wolf skewer",
    icon: "WolfMeatSkewer",
    health: 65,
    stamina: 21,
    eitr: 0,
    total: 86,
    rawMaterials: ["Wolf meat x1", "Mushroom x2", "Onion x1"],
    forkType: "Red",
    healing: "3",
    duration: "25:00",
    weight: 0.5,
    stackSize: 20,
    biomeProgression: "Mountain",
    craftingStation: 'Cauldron',
    craftingStationLevel: 3,
    craftingMultiplier: 0
  },
  {
    name: "Eyescream",
    icon: "Eyescream",
    health: 21,
    stamina: 65,
    eitr: 0,
    total: 86,
    rawMaterials: ["Greydwarf eye x3", "Freeze gland x1"],
    forkType: "Yellow",
    healing: "1",
    duration: "25:00",
    weight: 0.5,
    stackSize: 10,
    biomeProgression: "Mountain",
    craftingStation: 'Cauldron',
    craftingStationLevel: 3,
    craftingMultiplier: 0
  },
  {
    name: "Cloudberries",
    icon: "Cloudberries",
    health: 13,
    stamina: 40,
    eitr: 0,
    total: 53,
    rawMaterials: [],
    forkType: "Yellow",
    healing: "1",
    duration: "15:00",
    weight: 0.1,
    stackSize: 50,
    biomeProgression: "Plains",
    craftingStation: 'Farm',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Cooked lox meat",
    icon: "Cooked Lox Meat",
    health: 50,
    stamina: 16,
    eitr: 0,
    total: 66,
    rawMaterials: ["Lox meat x1"],
    forkType: "Red",
    healing: "4",
    duration: "20:00",
    weight: 1.0,
    stackSize: 20,
    biomeProgression: "Plains",
    craftingStation: 'Iron Cooking Station',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Fish wraps",
    icon: "FishWraps",
    health: 70,
    stamina: 23,
    eitr: 0,
    total: 93,
    rawMaterials: ["Cooked fish x2", "Barley flour x4"],
    forkType: "Red",
    healing: "4",
    duration: "25:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Plains",
    craftingStation: 'Cauldron',
    craftingStationLevel: 4,
    craftingMultiplier: 0
  },
  {
    name: "Lox meat pie",
    icon: "LoxPie",
    health: 75,
    stamina: 24,
    eitr: 0,
    total: 99,
    rawMaterials: ["Barley flour x4", "Cloudberries x2", "Lox meat x2"],
    forkType: "Red",
    healing: "4",
    duration: "30:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Plains",
    craftingStation: 'Stone Oven',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Blood pudding",
    icon: "Blood Pudding",
    health: 25,
    stamina: 75,
    eitr: 0,
    total: 100,
    rawMaterials: ["Thistle x2", "Bloodbag x2", "Barley flour x4"],
    forkType: "Yellow",
    healing: "2",
    duration: "30:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Plains",
    craftingStation: 'Cauldron',
    craftingStationLevel: 4,
    craftingMultiplier: 0
  },
  {
    name: "Bread",
    icon: "Bread",
    health: 23,
    stamina: 70,
    eitr: 0,
    total: 93,
    rawMaterials: ["Bread dough x1"],
    forkType: "Yellow",
    healing: "2",
    duration: "25:00",
    weight: 0.5,
    stackSize: 20,
    biomeProgression: "Plains",
    craftingStation: 'Stone Oven',
    craftingStationLevel: 0,
    craftingMultiplier: 2
  },
  {
    name: "Magecap",
    icon: "MushroomMagecap",
    health: 25,
    stamina: 25,
    eitr: 25,
    total: 75,
    rawMaterials: [],
    forkType: "White",
    healing: "1",
    duration: "15:00",
    weight: 0.1,
    stackSize: 50,
    biomeProgression: "Mistlands",
    craftingStation: 'Farm',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Jotun puffs",
    icon: "MushroomJotunPuffs",
    health: 25,
    stamina: 25,
    eitr: 0,
    total: 50,
    rawMaterials: [],
    forkType: "White",
    healing: "1",
    duration: "15:00",
    weight: 0.1,
    stackSize: 50,
    biomeProgression: "Mistlands",
    craftingStation: 'Farm',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Cooked egg",
    icon: "Cooked Egg",
    health: 35,
    stamina: 12,
    eitr: 0,
    total: 47,
    rawMaterials: ["Egg x1"],
    forkType: "Red",
    healing: "2",
    duration: "20:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Mistlands",
    craftingStation: 'Cauldron',
    craftingStationLevel: 1,
    craftingMultiplier: 0
  },
  {
    name: "Cooked seeker meat",
    icon: "Cookedseekermeat",
    health: 60,
    stamina: 20,
    eitr: 0,
    total: 80,
    rawMaterials: ["Seeker meat x1"],
    forkType: "Red",
    healing: "5",
    duration: "20:00",
    weight: 1.0,
    stackSize: 20,
    biomeProgression: "Mistlands",
    craftingStation: 'Iron Cooking Station',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Cooked hare meat",
    icon: "Cooked Hare Meat",
    health: 60,
    stamina: 20,
    eitr: 0,
    total: 80,
    rawMaterials: ["Hare meat x1"],
    forkType: "Red",
    healing: "5",
    duration: "20:00",
    weight: 1.0,
    stackSize: 20,
    biomeProgression: "Mistlands",
    craftingStation: 'Iron Cooking Station',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Cooked chicken meat",
    icon: "Cooked Chicken Meat",
    health: 60,
    stamina: 20,
    eitr: 0,
    total: 80,
    rawMaterials: ["Chicken meat x1"],
    forkType: "Red",
    healing: "5",
    duration: "20:00",
    weight: 1.0,
    stackSize: 20,
    biomeProgression: "Mistlands",
    craftingStation: 'Iron Cooking Station',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Meat platter",
    icon: "Meat Platter",
    health: 80,
    stamina: 26,
    eitr: 0,
    total: 106,
    rawMaterials: ["Seeker meat x1", "Lox meat x1", "Hare meat x1"],
    forkType: "Red",
    healing: "5",
    duration: "30:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Mistlands",
    craftingStation: 'Stone Oven',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Honey glazed chicken",
    icon: "Honey Glazed Chicken",
    health: 80,
    stamina: 26,
    eitr: 0,
    total: 106,
    rawMaterials: ["Chicken meat x1", "Honey x3", "Jotun puffs x2"],
    forkType: "Red",
    healing: "5",
    duration: "30:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Mistlands",
    craftingStation: 'Stone Oven',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Misthare supreme",
    icon: "Misthare Supreme",
    health: 85,
    stamina: 28,
    eitr: 0,
    total: 113,
    rawMaterials: ["Hare meat x1", "Jotun puffs x3", "Carrot x2"],
    forkType: "Red",
    healing: "5",
    duration: "25:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Mistlands",
    craftingStation: 'Stone Oven',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Salad",
    icon: "Salad",
    health: 26,
    stamina: 80,
    eitr: 0,
    total: 106,
    rawMaterials: ["Jotun puffs x3", "Onion x3", "Cloudberries x3"],
    forkType: "Yellow",
    healing: "3",
    duration: "25:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Mistlands",
    craftingStation: 'Cauldron',
    craftingStationLevel: 5,
    craftingMultiplier: 0
  },
  {
    name: "Mushroom omelette",
    icon: "Mushroom Omelette",
    health: 28,
    stamina: 85,
    eitr: 0,
    total: 113,
    rawMaterials: ["Egg x3", "Jotun puffs x3"],
    forkType: "Yellow",
    healing: "3",
    duration: "25:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Mistlands",
    craftingStation: 'Cauldron',
    craftingStationLevel: 5,
    craftingMultiplier: 0
  },
  {
    name: "Fish n' bread",
    icon: "FishAndBread",
    health: 30,
    stamina: 90,
    eitr: 0,
    total: 120,
    rawMaterials: ["Anglerfish x1", "Bread dough x2"],
    forkType: "Yellow",
    healing: "3",
    duration: "30:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Mistlands",
    craftingStation: 'Stone Oven',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Stuffed mushroom",
    icon: "MagicallyStuffedShroom",
    health: 25,
    stamina: 12,
    eitr: 75,
    total: 112,
    rawMaterials: ["Magecap x3", "Blood clot x1", "Turnip x2"],
    forkType: "Blue",
    healing: "3",
    duration: "25:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Mistlands",
    craftingStation: 'Stone Oven',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Yggdrasil porridge",
    icon: "Yggdrasil Porridge",
    health: 27,
    stamina: 13,
    eitr: 80,
    total: 120,
    rawMaterials: ["Sap x4", "Barley x3", "Royal jelly x2"],
    forkType: "Blue",
    healing: "3",
    duration: "25:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Mistlands",
    craftingStation: 'Cauldron',
    craftingStationLevel: 5,
    craftingMultiplier: 0
  },
  {
    name: "Seeker aspic",
    icon: "Seeker Aspic",
    health: 28,
    stamina: 14,
    eitr: 85,
    total: 127,
    rawMaterials: ["Seeker meat x2", "Magecap x2", "Royal jelly x2"],
    forkType: "Blue",
    healing: "3",
    duration: "30:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Mistlands",
    craftingStation: 'Cauldron',
    craftingStationLevel: 5,
    craftingMultiplier: 2
  },
  {
    name: "Smoke puff",
    icon: "MushroomSmokePuff",
    health: 15,
    stamina: 15,
    eitr: 0,
    total: 30,
    rawMaterials: [],
    forkType: "White",
    healing: "1",
    duration: "15:00",
    weight: 0.1,
    stackSize: 50,
    biomeProgression: "Ashlands",
    craftingStation: 'Gather',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Fiddlehead",
    icon: "Fiddlehead",
    health: 30,
    stamina: 30,
    eitr: 0,
    total: 60,
    rawMaterials: [],
    forkType: "White",
    healing: "1",
    duration: "15:00",
    weight: 0.1,
    stackSize: 50,
    biomeProgression: "Ashlands",
    craftingStation: 'Gather',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Vineberry cluster",
    icon: "Vineberry",
    health: 30,
    stamina: 30,
    eitr: 30,
    total: 90,
    rawMaterials: [],
    forkType: "White",
    healing: "1",
    duration: "15:00",
    weight: 0.1,
    stackSize: 50,
    biomeProgression: "Ashlands",
    craftingStation: 'Farm',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Cooked asksvin tail",
    icon: "Cookedasksvintail", // lower case for some reason
    health: 70,
    stamina: 24,
    eitr: 0,
    total: 94,
    rawMaterials: ["Asksvin tail x1"],
    forkType: "Red",
    healing: "6",
    duration: "20:00",
    weight: 1.0,
    stackSize: 20,
    biomeProgression: "Ashlands",
    craftingStation: 'Cooking Station',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Cooked volture meat",
    icon: "Cooked Volture Meat",
    health: 70,
    stamina: 24,
    eitr: 0,
    total: 94,
    rawMaterials: ["Volture meat x1"],
    forkType: "Red",
    healing: "6",
    duration: "20:00",
    weight: 1.0,
    stackSize: 20,
    biomeProgression: "Ashlands",
    craftingStation: 'Cooking Station',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Cooked bonemaw meat",
    icon: "Cooked bonemaw meat",
    health: 90,
    stamina: 30,
    eitr: 0,
    total: 120,
    rawMaterials: ["Bonemaw meat x1"],
    forkType: "Red",
    healing: "6",
    duration: "25:00",
    weight: 10.0,
    stackSize: 50,
    biomeProgression: "Ashlands",
    craftingStation: 'Iron Cooking Station',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Fiery svinstew",
    icon: "Fiery Svinstew",
    health: 95,
    stamina: 32,
    eitr: 0,
    total: 127,
    rawMaterials: ["Asksvin tail x1", "Vineberry cluster x2", "Smoke puff x1"],
    forkType: "Red",
    healing: "6",
    duration: "25:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Ashlands",
    craftingStation: 'Cauldron',
    craftingStationLevel: 5,
    craftingMultiplier: 0
  },
  {
    name: "Mashed meat",
    icon: "Mashed Meat",
    health: 100,
    stamina: 34,
    eitr: 0,
    total: 134,
    rawMaterials: ["Asksvin tail x1", "Volture meat x1", "Fiddlehead x1"],
    forkType: "Red",
    healing: "6",
    duration: "25:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Ashlands",
    craftingStation: 'Cauldron',
    craftingStationLevel: 6,
    craftingMultiplier: 0
  },
  {
    name: "Piquant pie",
    icon: "Piquant Pie",
    health: 105,
    stamina: 35,
    eitr: 0,
    total: 140,
    rawMaterials: ["Asksvin tail x2", "Vineberry cluster x2", "Barley flour x4"],
    forkType: "Red",
    healing: "6",
    duration: "30:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Ashlands",
    craftingStation: 'Stone Oven',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Spicy marmalade",
    icon: "Spicy Marmalade",
    health: 30,
    stamina: 90,
    eitr: 0,
    total: 120,
    rawMaterials: ["Vineberry cluster x3", "Honey x1", "Fiddlehead x1"],
    forkType: "Yellow",
    healing: "4",
    duration: "25:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Ashlands",
    craftingStation: 'Cauldron',
    craftingStationLevel: 5,
    craftingMultiplier: 0
  },
  {
    name: "Scorching medley",
    icon: "Scorching Medley",
    health: 32,
    stamina: 95,
    eitr: 0,
    total: 127,
    rawMaterials: ["Jotun puffs x3", "Onion x3", "Fiddlehead x3"],
    forkType: "Yellow",
    healing: "4",
    duration: "25:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Ashlands",
    craftingStation: 'Cauldron',
    craftingStationLevel: 6,
    craftingMultiplier: 3
  },
  {
    name: "Roasted crust pie",
    icon: "Roasted Crust Pie",
    health: 34,
    stamina: 100,
    eitr: 0,
    total: 134,
    rawMaterials: ["Volture egg x1", "Vineberry cluster x2", "Barley flour x4"],
    forkType: "Yellow",
    healing: "4",
    duration: "30:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Ashlands",
    craftingStation: 'Stone Oven',
    craftingStationLevel: 0,
    craftingMultiplier: 0
  },
  {
    name: "Sizzling berry broth",
    icon: "Sizzling Berry Broth",
    health: 28,
    stamina: 14,
    eitr: 85,
    total: 127,
    rawMaterials: ["Sap x3", "Vineberry cluster x2", "Fiddlehead x2"],
    forkType: "Blue",
    healing: "4",
    duration: "25:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Ashlands",
    craftingStation: 'Cauldron',
    craftingStationLevel: 5,
    craftingMultiplier: 0
  },
  {
    name: "Sparkling shroomshake",
    icon: "Sparkling Shroomshake",
    health: 30,
    stamina: 15,
    eitr: 90,
    total: 135,
    rawMaterials: ["Sap x4", "Magecap x2", "Vineberry cluster x2", "Smoke puff x2"],
    forkType: "Blue",
    healing: "4",
    duration: "25:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Ashlands",
    craftingStation: 'Cauldron',
    craftingStationLevel: 6,
    craftingMultiplier: 0
  },
  {
    name: "Marinated greens",
    icon: "Marinated Greens",
    health: 32,
    stamina: 16,
    eitr: 95,
    total: 143,
    rawMaterials: ["Sap x3", "Magecap x2", "Fiddlehead x2", "Smoke puff x2"],
    forkType: "Blue",
    healing: "4",
    duration: "30:00",
    weight: 1.0,
    stackSize: 10,
    biomeProgression: "Ashlands",
    craftingStation: 'Cauldron',
    craftingStationLevel: 6,
    craftingMultiplier: 0
  }
];


export default foodData;
