import { Food } from "../../db/foodData";
import { getStationColor } from "../../helperFunctions/getCookingStation";

interface ICraftingStationCellProps {
    cell: {
        craftingStation: Food['craftingStation'];
        craftingStationLevel: Food['craftingStationLevel'];
      };
}

export default function CraftingStationCell({ cell }: ICraftingStationCellProps) {
    return (
      <div>
        <CraftingStationLine 
          station={cell.craftingStation} 
          level={cell.craftingStationLevel} 
        />
      </div>
    );
  }

type CraftingStationLineProps = {
    station: string;
    level: number;
}
  
function CraftingStationLine({ station, level }: CraftingStationLineProps) {
    
    return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <div style={{ 
            backgroundColor: getStationColor(station), 
            textAlign: 'center',
            padding: '5px 10px',
            borderRadius: level > 0 ? '5px 0 0 5px' : '5px',
            fontSize: '16px',
            fontFamily: 'Arial, sans-serif',
            color: '#fff',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            margin: '5px 0',
            display: 'inline-block'
        }}>
        {station}
        </div>
        {level > 0 && (
        <div style={{
            backgroundColor: '#333', // A darker color for the level tag
            textAlign: 'center',
            padding: '5px 10px',
            borderRadius: '0 5px 5px 0',
            fontSize: '16px',
            fontFamily: 'Arial, sans-serif',
            color: '#fff',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            margin: '5px 0',
            display: 'inline-block',
            marginLeft: '2px' // Small space between tags
        }}>
            Lvl {level}
        </div>
        )}
    </div>
    );

}