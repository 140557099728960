import React, { useState } from 'react';
import './NavBar.css'; 
import logo from '../../images/Logo.png';
import HomePage from '@mui/icons-material/HomeOutlined';
import FoodTable from '@mui/icons-material/Restaurant';
import CookingStations from '@mui/icons-material/LocalFireDepartment';
import AboutIcon from '@mui/icons-material/InfoOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';



interface NavBarItemProps {
  label: string;
  path: string;
}

const NavBarItem: React.FC<NavBarItemProps> = ({ label, path }) => {
  // create a switch statement to return the correct icon for the label
  const getIcon = (label: string) => {
    switch (label) {
      case "Home":
        return <HomePage sx={{ color: '#00ff00' }} />;
      case "Food Table":
        return <FoodTable sx={{ color: '#149edc' }} />;
      case "Cooking Stations":
        return <CookingStations sx={{ color: '#964B00' }} />;
      case "Filters":
        return <FilterAltIcon sx={{ color: 'grey' }} />;
      case "About":
        return <AboutIcon sx={{ color: '#003399' }} />;
      default:
        return <HomePage sx={{ color: '#000000' }} />;
    }
  }

  return (
    <li className="nav-item">      
        <a href={path}>{getIcon(label)}{label}</a>
    </li>
  );
};

export default function NavBar() {
  const [isNavVisible, setIsNavVisible] = useState(false);

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  return (
    <div className="navbar-container">
      <div className="logo">
        <a href="/">
          <img src={logo} alt="Valheim Cooking" />
        </a>
      </div>
      <div className="site-title">
        <h1>Valheim Cooking</h1>
        <div className="byline">by <span className="bug-busters">Bug Busters</span></div>
      </div>
      <button className="nav-toggle" onClick={toggleNav}>☰</button> {/* Simple hamburger icon */}
      <div className={`divider ${isNavVisible ? 'show' : ''}`}></div>
      <nav className={`navbar ${isNavVisible ? 'show' : ''}`}>
        <ul className="nav-list">
          <NavBarItem label="Home" path="/" />
          <NavBarItem label="Food Table" path="/food-table" />
          <NavBarItem label="Cooking Stations" path="/cooking-stations" />
          <NavBarItem label="Filters" path="/Settings" />
          {/* <NavBarItem label="About" path="/about" />  */}
        </ul>
      </nav>
    </div>
  );
}
