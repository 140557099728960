import '../App.css';

export default function Home() {
    return (
        <div className='home-page'>
            <div className="splash-container">
                <img src={`${process.env.PUBLIC_URL}/images/Splash.png`} alt="Splash screen for Valheim Cooking by Bug Busters" />
            </div>
            <div className='text'>
                <h1>
                    Welcome to Valheim Cooking
                </h1>
                <p>
                    This site is a collection of food items and their stats in the game Valheim. Valheim has a plethora of food items that can be cooked and consumed to gain various benefits. Use this site to discover what food items you can cook and the benefits they provide.
                </p>
                <main aria-label="Instructions">
                    <h3>How to Use</h3>
                    <p>
                        The food table tab is the main screen where you can view available food types and their benefits. Decide whether you want to focus on stamina, health, or a combination of both.
                    </p>
                    <p>
                        To get the most out of the site, use the filters tab. The basic filter allows you to select which biomes you have access to, and the food table will only show foods that can be made from those biomes. As you progress, you can add extra biomes to the filter.
                    </p>
                    <p>
                        You can also add additional filtering by selecting which cooking stations you have access to and what level your cauldron is.
                    </p>
                    <p>
                        Advanced filtering allows you to filter foods based on their effect duration and weight. For example, if you haven't found the trader yet and don't have a belt, you can filter out heavier foods. Or perhaps you prefer food with the same duration so you don't have to constantly monitor your eating.
                    </p>
                </main>
                <h3>Under Construction</h3>
                <p>
                    We're still adding features to the site, so expect new elements to be added over time.
                </p>
            </div>
        </div>
    );
}
