

interface ITableBarProps {
    value: number;
    maxValue: number;
    type: FoodTableBarType;
}

export enum FoodTableBarType {
    Health = 'red',
    Stamina = 'gold',
    Eitr = 'blue',
    Healing = 'green',
    Total = 'purple'
}

/**
 * A bar component for displaying a value as a bar.
 * @param props The properties for the component.
 * @returns The JSX element for the component. 
 */
export default function TableBar(props: ITableBarProps) {
  return (
    props.value > 0 ?
        <div className="number-cell">
          <div className="bar-container">
            <div className="bar" style={{ width: `${(props.value / props.maxValue) * 100}%`, backgroundColor: props.type }}></div>
            <span className="bar-text">{props.value}</span>
          </div>
        </div>
    : null
  );
}