import React from 'react';
import FoodCard from './FoodCard';
import { getStationColor } from '../../helperFunctions/getCookingStation';
import Paper from '@mui/material/Paper';
import { getFilterFoodData } from '../../helperFunctions/getFilteredFoodData';

interface CookingStationCardProps {
  stationName: string;
  selectedBiomes: string[];
}

const CookingStationCard: React.FC<CookingStationCardProps> = ({ stationName, selectedBiomes }) => {
  // Find all foods that can be made at the given station and match selected biomes
  const matchingFoods = getFilterFoodData().filter(
    food =>
      food.craftingStation.includes(stationName)
  );

  // If no matching foods, return null
  if (matchingFoods.length === 0) {
    return null;
  }

  // Inline styles for the cooking station card with dynamic background color
  const stationCardStyle: React.CSSProperties = {
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '16px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: getStationColor(stationName),
    textAlign: 'center', // Center the title
    display: 'inline-block',
  };

  const titleStyle: React.CSSProperties = {
    fontFamily: 'Arial, sans-serif', // Change to your preferred font
    fontWeight: 'bold', // Make the font bold
    fontSize: '1.5em', // Adjust the font size
    marginBottom: '16px'
  };

  return (
    <Paper elevation={6} style={stationCardStyle}>
      <h2 style={titleStyle}>{stationName}</h2>
      <div style={foodListStyle}>
        {matchingFoods.map(food => (
          <FoodCard key={food.name} foodName={food.name} />
        ))}
      </div>
    </Paper>
  );
};

// Inline styles for the food list inside the cooking station card
const foodListStyle: React.CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '16px',
  justifyContent: 'center' // Center the food cards within the station card
};

export default CookingStationCard;
