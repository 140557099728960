import React, { useEffect, useMemo, useState } from 'react';
import {
  useTable,
  useSortBy,
  useFilters,
  Column,
  TableInstance,
  ColumnInstanceWithFilter,
} from 'react-table';
import '../../App.css';
import { getForkTypeColor } from './ForkType';
import TableBar, { FoodTableBarType } from './Bar';
import ForkIcon from '@mui/icons-material/Restaurant';
import BiomeTag from '../Tags/BiomeTag';
import CraftingStationCell from './CraftingStationCell';
import BiomeFilter from '../BiomeFilter/BiomeFilter';
import { Link } from 'react-router-dom';
import DurationTag from '../Tags/DurationTag';
import { getFilterFoodData } from '../../helperFunctions/getFilteredFoodData';
import { maxHealth, maxStamina, maxEitr, maxTotal, maxHealing, TotalFoodsInDatabase } from '../../App';
import { Food } from '../../db/foodData';
import { getImageURLRemoveSpaces } from '../../helperFunctions/getImageURL';


// Define a default UI for filtering
function DefaultColumnFilter({
  column,
}: {
  column: ColumnInstanceWithFilter<Food>;
}) {
  const { filterValue, preFilteredRows, setFilter } = column;
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

const FoodTable: React.FC = () => {
  const allColumns: Column<Food>[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        show: true,
        width: 200,
        Cell: ({ cell }) => {
          return (
            <div className="food-name-cell">
              <img 
                src={getImageURLRemoveSpaces(cell.row.original.icon)} 
                alt={cell.value} 
                style={{ width: '32px', height: '32px', marginRight: '8px' }} 
              />
              <Link to={`/${cell.value.toLowerCase()}`} className="food-link">{cell.value}</Link>
            </div>
          );
        }
      },
      {
        Header: 'Health',
        accessor: 'health',
        show: true,
        width: 150,
        Cell: ({ cell }) => <TableBar value={cell.value} maxValue={maxHealth} type={FoodTableBarType.Health} />
      },
      {
        Header: 'Stamina',
        accessor: 'stamina',
        show: true,
        width: 150,
        Cell: ({ cell }) => <TableBar value={cell.value} maxValue={maxStamina} type={FoodTableBarType.Stamina} />
      },
      {
        Header: 'Eitr',
        accessor: 'eitr',
        show: true,
        width: 150,
        Cell: ({ cell }) => <TableBar value={cell.value} maxValue={maxEitr} type={FoodTableBarType.Eitr} />
      },
      {
        Header: 'Total',
        accessor: 'total',
        show: true,
        width: 150,
        Cell: ({ cell }) => <TableBar value={cell.value} maxValue={maxTotal} type={FoodTableBarType.Total} />
      },
      {
        Header: 'Raw Materials',
        accessor: 'rawMaterials',
        show: true,
        width: 150,
        Cell: ({ cell }) => <span>{cell.value.join(', ')}</span>,
      },
      {
        Header: 'Fork',
        accessor: 'forkType',
        show: true,
        width: 40,
        Cell: ({ cell }) => <div className='center'><ForkIcon sx={{ color: getForkTypeColor(cell.value) }} /></div>
      },
      {
        Header: 'Healing',
        accessor: 'healing',
        show: true,
        width: 150,
        Cell: ({ cell }) => <TableBar value={cell.value} maxValue={maxHealing} type={FoodTableBarType.Healing} />
      },
      {
        Header: 'Duration',
        accessor: 'duration',
        show: true,
        width: 150,
        Cell: ({ cell }) => <DurationTag duration={cell.value} />
      },
      {
        Header: 'Weight',
        accessor: 'weight',
        show: true,
        width: 150,
        Cell: ({ cell }) => <span className="number-cell">{cell.value}</span>,
      },
      {
        Header: 'Stack',
        accessor: 'stackSize',
        show: true,
        width: 50,
        Cell: ({ cell }) => <span className="number-cell">{cell.value}</span>,
      },
      {
        Header: 'Biome',
        accessor: 'biomeProgression',
        show: true,
        width: 150,
        Cell: ({ cell }) => <BiomeTag biome={cell.value} link /> 
      },
      {
        Header: 'Crafting Station',
        accessor: 'craftingStation',
        show: true,
        width: 150,
        Cell: ({ cell }) => (
          <CraftingStationCell 
            cell={{ 
              craftingStation: cell.row.original.craftingStation, 
              craftingStationLevel: cell.row.original.craftingStationLevel 
            }} 
          />
        ),
      },
      {
        Header: 'Crafting Multiplier',
        accessor: 'craftingMultiplier',
        show: true,
        width: 150,
        Cell: ({ cell }) => cell.value > 0 ? <span className="number-cell">{cell.value}</span> : <div></div>,
      },
    ],
    []
  );


  const [reload, setReload] = useState<boolean>(false);
  const [data, setData] = useState<Food[]>(() => { return getFilterFoodData() });

  useEffect(() => {
    setData(getFilterFoodData());
  }, [reload]);

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const visibleColumns = useMemo(
    () => allColumns.filter(column => column.show),
    [allColumns]
  );


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns: visibleColumns,
      data,
      defaultColumn,
    },
    useFilters,
    useSortBy
  ) as TableInstance<Food>;

  return (
    <div className="food-table">
      <div style={filterContainerStyle}>
        <div style={{ marginTop: '8px'}}>
          {getFilterFoodData().length} foods found, {TotalFoodsInDatabase - getFilterFoodData().length} filtered out.
        </div>
        <BiomeFilter onBiomesChange={() => setReload(!reload)} />
      </div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{ width: column.width }}>
                  {column.render('Header')}
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FoodTable;

const filterContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  marginTop: '0px', 
  marginRight: '0px',
  justifyContent: 'space-between',
};
